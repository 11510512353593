import React from "react"
import SEO from "../components/seo"
import netlifyIdentity from 'netlify-identity-widget';
import Img from "gatsby-image"

//components

//material-ui
import Box from '@material-ui/core/Box'

//styles
import styles from "../styles/soko.module.css"

if (typeof window !== 'undefined' && typeof netlifyIdentity.init !== `undefined`) {
    netlifyIdentity.init();
}

function SokoPage(props) {
    console.log("props", props)
    return (
        <Box width="auto" className={styles.box}>
            <SEO title="Soko" />
            <Img className={styles.icon} fixed={props.data.file.childImageSharp.fixed} />
            <a className={styles.mail} href="mailto:cordell.blackford@gmail.com">
                Sōko
            </a>
        </Box>
    )
}

export default SokoPage

export const query = graphql`
  query {
    file(relativePath: { eq: "icon_80.png" }) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        # Makes it trivial to update as your page's design changes.
        fixed(width: 40, height: 40) {
          ...GatsbyImageSharpFixed
        }
      }
    }
  }
`